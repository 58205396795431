/* You can add global styles to this file, and also import other style files */
@import './src/app/shared/constants/scss/variables.scss';

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: $host-font-size-for-full-width;
}

.coefficient-value {
  .mat-form-field-underline {
    display: none;
  }
}

.mat-table {
  .mat-sort-header-content {
    text-align: left;
  }

  .mat-sort-header-arrow[style] {

    .mat-sort-header-stem {
      display: none;
    }

    .mat-sort-header-indicator {
      opacity: 1;
      font-weight: bold;

      .mat-sort-header-pointer-left,
      .mat-sort-header-pointer-right,
      .mat-sort-header-pointer-middle {
        display: none;
      }
    }
  }

  [aria-sort='ascending'] {
    .mat-sort-header-arrow {
      .mat-sort-header-indicator {
        &::before {
          font: normal normal normal 1.1rem/1 FontAwesome;
          content: "\f0d7";
          position: absolute;
          top: .2rem;
        }
      }
    }
  }

  [aria-sort='descending'] {
    .mat-sort-header-arrow {
      .mat-sort-header-indicator {
        &::before {
          font: normal normal normal 1.1rem/1 FontAwesome;
          content: "\f0d8";
          position: absolute;
          top: -.9rem;
        }
      }
    }
  }
}

.cancel-form-bar {
  background: #e2e3e5;
  color: #5d6164;
}

.update-form-bar {
  background: #d4edda;
  color: #3d774b;
}

.error-form-bar {
  background: #f8d7da;
  color: #975057;
}

.btn-primary {
  @extend %btn-done;
}
