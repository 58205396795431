/********************
font-size
*******************/
$host-font-size-for-full-width: 16px;
$font12: 0.750em;
$font18: 1.125em;
$font28: 1.750em;
$font30: 1.875em;

/********************
colors
*******************/
$color-blue: rgba(0, 58, 102, 0.7);
$hover-color-blue: #306085;
$btn-disabled-color: #b8c8d4;
$link-color: #007bff;
$link-hover-color: #0056b3;

/********************
mixins
*******************/
@mixin btn-background($color) {
  background-color: $color;
  border-color: $color;
}

/********************
extends
*******************/
%full-width {
  width: 100%;
}

%copy-to-clipboard-span {
  font-size: 1em;
  cursor: pointer;
  padding: 0.375rem 6px;
  margin-left: 10px;

  &:active {
    background-color: #ddd;
    border-radius: 5px;
    color: #fff;
  }
}

%refresh-to-clipboard-span {
  font-size: 1.2em;
  cursor: pointer;
  margin-left: 5px;

  &:active {
    background-color: red;
    border-radius: 5px;
    color: #fff;
  }
}

%btn-done {
  @include btn-background($color-blue);

  &:hover {
    @include btn-background($hover-color-blue);
  }
;

  &:not(:disabled):not(.disabled):active {
    @include btn-background($hover-color-blue);
  }

  &:focus {
    @include btn-background($color-blue);
  }

  .disabled {
    @include btn-background($btn-disabled-color);
  }

  &:disabled {
    @include btn-background($btn-disabled-color);
  }
}

%login-form {
  width: 350px;
  height: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -150px;
  margin-left: -175px;
  background-color: #f3f3f3;
  border-radius: 20px;
  padding: 30px 40px;

  .full-width {
    width: 100%;
  }

  .eye {
    cursor: pointer;
  }
}

%mat-tab-menu {
  a {
    background-color: #c4c4c4;
    color: #fff;
    opacity: 1;

    &:hover {
      text-decoration: none;
    }
  }

  .mat-ink-bar {
    background: #fff !important;
    height: 4px;
  }

  .active {
    background-color: #f3f3f3;
    color: #616161;
    border-bottom: 3px solid white;
    transition: 0.1s ease-in-out;
  }
}

%table-style {
  .mat-header-cell {
    font-size: $font12;
  }

  .mat-cell {
    font-size: $font12;
  }

  .mat-footer-cell {
    font-size: $font12;
  }

  .mat-row:nth-child(even) {
    background-color: #fff;
  }

  .mat-row:nth-child(odd) {
    background-color: #f7f7f7;
  }

  .mat-header-row {
    background-color: #e3e3e3;
  }
}

%btn-edit {
  padding: 0;
  margin: 0;
  color: $color-blue;
}

%bet-value {
  width: 80%;
  margin: auto 10px;
}

%as-link {
  cursor: pointer;
  color: $link-color;

  &:hover {
    color: $link-hover-color;
    text-decoration: underline;
  }
}

%wrapper-history-frame {
  height: 100vh;
  background-color: #000;
  border-radius: 5px;
}

%hidden-text {

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

%hidden-text-active {

  span {

    &:active {
      color: #dadada;
    }
  }
}

/********************
animation
*******************/
%img-show-animation {
  -webkit-animation: fadeIn 0.5s forwards;
  animation: fadeIn 0.5s forwards;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

%img-hide-animation {
  -webkit-animation: fadeOut 0.5s forwards;
  animation: fadeOut 0.5s forwards;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
    display: none;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
    display: none;
  }
}

%content-show-animation {
  -webkit-animation: showContent 0.3s forwards;
  animation: showContent 0.3s forwards;
}

@-webkit-keyframes showContent {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes showContent {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
